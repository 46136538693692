import React from 'react';
import { graphql, Link } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

import Sidebar from "../components/sidebar";
import styled from '@emotion/styled';
import Seo from '../components/seo/seo';

export const query = graphql`query BlogPostListing($skip: Int!, $limit: Int!) {
		allFile(
			filter: {absolutePath: {regex: "//posts//"}, extension: {regex: "/md|mdx|markdown/"}}
			sort: {fields: [childMarkdownRemark___frontmatter___date, childMdx___frontmatter___date], order: DESC}
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					md: childMarkdownRemark {
						excerpt
						timeToRead
						frontmatter {
							slug
							title
							date(formatString: "MMMM DD, YYYY", locale: "it_IT")
							imghero
							hero {
								childImageSharp {
									...ImageSharpFragment
								}
							}
						}
					}
					mdx: childMdx {
						excerpt
						timeToRead
						frontmatter {
							slug
							title
							date(formatString: "MMMM DD, YYYY", locale: "it_IT")
							imghero
							hero {
								childImageSharp {
									...ImageSharpFragment
								}
							}
						}
					}
				}
			}
		}
	}

	fragment ImageSharpFragment on ImageSharp {
		gatsbyImageData(
			width: 700
			quality: 85
			placeholder: BLURRED
			layout: CONSTRAINED
		)
	}
`

const Article = styled.article`
	/* filter: drop-shadow(rgba(0, 0, 0, 0.5) 0px 4px 8px); */
	box-shadow: 0px 3px 10px rgba(25, 17, 24, 0.3);
	padding: 1.2rem;
	border-radius: 4px;
	margin-bottom: 0.7rem;
	margin-left: 2rem;
	max-width: 42rem;
	min-height: 202px;
	> p {
		font-size: 0.8rem;
		color: #fff;
		margin-bottom: 0.5rem;
		@media (max-width: 500px) { padding: 0.5rem 0; }
		&::first-letter {text-transform: uppercase;}
	}
	> a {
		&:hover { text-decoration: none;}
		> h2 {
		font-weight: 800;
		margin-bottom: 0.1rem;
		color: #fff;
		@media (max-width: 500px) {font-size: 1.3rem;}
		}
	}
	p.riassunto-scheda {
		@media (max-width: 500px) {display: none;}
	}
	span.tempo-lettura {
		@media (min-width: 990px) {display: none;}
	}
	.read-more {
		font-size: 0.9rem;
		text-decoration: none;
		color: #ff9000;
		&:hover { text-decoration: none;}

		@media (max-width: 500px) {
			padding: 0.5em;
			background-color: #682aa53d;
  		border: 1px solid black;
		}

	} 
	/* BACKGROUND IMAGE INSIDE ARTICLE LISTING */
	background-image: 
		linear-gradient(rgba(77, 50, 23, 0.65),rgba(23, 28, 77, 0.65)), 
		url(${props => props.img_background || '/immagini-bkgrnd/Liquid-Cheese.svg'});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	@media (max-width: 999px) {
		margin: 0.7rem auto;
		margin-top:0;
	}
	@media (max-width: 780px) {
		border-radius: 0px;
	}
	@media (max-width: 500px) {
		margin-top: 0.5rem;
	}
`

const Pagination = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
	margin-bottom: 10px;
	max-width: 42rem;
	margin-left: 2rem;
	padding: 1rem;
	> a > h3 { color: #ff9000; }
	> a:hover { text-decoration: none; }
	@media (max-width: 999px) {
		margin-left: 0px;
		max-width: 100%;
		> a > h3 { font-size: 1rem; }
	}
`

const Listing = ({ data, pageContext }) => {

	const rawPosts = data.allFile.edges

	const posts = rawPosts.reduce((finalArray, { node }) => {

		if ( node.md !== null ) finalArray.push(node.md)
		if ( node.mdx !== null) finalArray.push(node.mdx)

		return finalArray
	}, [])


	const { currentPage, numPages } = pageContext
	const isFirst = currentPage === 1
	const isLast = currentPage === numPages
	const prevPage = currentPage - 1 === 1 ? '/' : (`/index/${currentPage - 1}`).toString()
	const nextPage = (`/index/${currentPage + 1}`).toString()

	// Component Inline

/* Aquí sería con propiedades genericas "props" y las propiedades dentro {props.propiedad1} etc...
const Links = props => ([...] <h2>{props.propiedad1} </h2> [...]) */

/* Aquí en vez paso las propiedades directamente, sin props.
Entonces deconstruisco y las nombro adentro de la parentesis ({propiedad1, propriedad2, etc}) */

// al parecer el fragment que envuelve toda la funcion es importante, si no gatsby me da error ya en develop
	const Scheda = ({ imagendefondo, percorso, titolo, fecha, resumen, tempo }) => (
		<Article className="Article" img_background={imagendefondo} >
			<Link to={percorso} >
				<h2>{titolo}</h2>
			</Link>
			<p>{fecha} <span className="tempo-lettura" role="img" aria-label="Tempo di lettura"> | 🕐 {tempo} min</span></p>
			<p className="riassunto-scheda">{resumen}</p>
			<Link className="read-more" to={percorso}>Leggi di più</Link> 
		</Article>
	)
	
	return <>
    <Seo
        ogImg="/img_og/riot-firefighter-home.jpg"
    />

        <main className="MainHome">
            <Sidebar />
            <div>
                {posts.map(( node ) => (
                    <Scheda
											key={node.frontmatter.slug}
											imagendefondo={node.frontmatter.hero ? (getSrc(node.frontmatter.hero)) : (node.frontmatter.imghero)}
											percorso={node.frontmatter.slug}
											titolo={node.frontmatter.title}
											fecha={node.frontmatter.date}
											resumen={node.excerpt}
											tempo={node.timeToRead}
                    />
                ))}
                <Pagination>
                    {!isFirst && (
                        <Link to={prevPage} rel="prev">
                            <h3>
                                ← Torna indietro
                            </h3>
                </Link>
                    )}
                    {!isLast && (
                        <Link to={nextPage} rel="next">
                            <h3>
                                Prossima Pagina →
                            </h3>
                </Link>
                    )}
                </Pagination>
            </div>
        </main>
</>;
}

export default Listing

