import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'

const Archive = () => (
	<StaticQuery
    query={POST_ARCHIVE_QUERY}
    render={( { allFile: { edges }} ) => {

			const posts = edges.reduce((finalArray, { node }) => {
				if ( node.md !== null ) finalArray.push(node.md)
				if ( node.mdx !== null) finalArray.push(node.mdx)
				return finalArray
			}, [])

			return (
			<>
        <Sidebar>
					<h3>For Conte's bimbe</h3>
					<Link to='/featured/conte-meme-generator'>
						<img 
							style={{ borderRadius: '15px', boxShadow: '0px 3px 10px rgba(25,17,24,0.3)', marginBottom: '1em' }}
							src='/img_og/conte-meme-generator-anonimoconiglio-preview.png' 
							alt='Conte Meme Generator' 
							title='Generatore di Meme di Conte'
						/>
					</Link>
          <h3>Ultimi 5 post</h3>
          <ArchiveList>
            {posts.map(post => (
              <li key={post.frontmatter.slug}>
								<Link to={post.frontmatter.slug}>
									<h4>{post.frontmatter.title}</h4>
								</Link>
                {/* Scritto il  */}<strong>{post.frontmatter.giorno} {post.frontmatter.mese}</strong> / {post.frontmatter.anno}
                <br />
                Tempo di lettura: <strong>{post.timeToRead} min</strong>
              </li>
            ))}
          </ArchiveList>
        </Sidebar>
      </>
			)
		}}
  />
)

export default Archive


/* STYLE */
const Sidebar = styled.aside`
  grid-row-start:1;
  grid-column-start: 1;
  margin: 0 auto;
	height: max-content;
  position: sticky;
	top: 5.45rem;
  padding-top: 26px;
  padding-left: 3rem;
/*   
  box-shadow: 0px 3px 10px rgba(25, 17, 24, 0.3); 
  background-color: blanchedalmond;
  */
  @media (max-width: 1199px) {
		padding-left: 1.2rem;

	}
  @media (max-width: 999px) {
	  display: none;
  }
`;
const ArchiveList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    font-size: 0.75rem;
  }
	h4 {
		margin-bottom: 0.3rem;
		margin-top: 1.2rem;
	}
  a {
    font-size: 0.8rem;
    text-decoration: none;
		color: #ff9000;
  }
`

/* QUERY */
const POST_ARCHIVE_QUERY = graphql`
query BlogPostArchive {
  allFile(
    filter: {absolutePath: {regex: "//posts//"}, extension: {regex: "/md|mdx|markdown/"}}
    sort: {fields: [childMarkdownRemark___frontmatter___date, childMdx___frontmatter___date], order: DESC}
    limit: 5
  ) {
    totalCount
    edges {
      node {
        md: childMarkdownRemark {
          timeToRead
          wordCount { words }
          frontmatter {
            slug
            title
            giorno: date(formatString: "DD")
            mese: date(formatString: "MMMM", locale: "it_IT")
            anno: date(formatString: "YYYY")
          }
        }
        mdx: childMdx {
          timeToRead
          wordCount { words }
          frontmatter {
            slug
            title
            giorno: date(formatString: "DD")
            mese: date(formatString: "MMMM", locale: "it_IT")
            anno: date(formatString: "YYYY")
          }
        }
      }
    }
  }
}
`